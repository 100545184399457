import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import 'sweetalert2/src/sweetalert2.scss'
import Footer from './components/footer/Footer'
import Header from './components/header/Header'

function Layout() {
  const queryClient = new QueryClient()
  const { pathname } = useLocation()
  useEffect(() => {
    window.scroll(0, 0)
  }, [pathname])
  return (
    <QueryClientProvider client={queryClient}>
      <Header />
      <Outlet />
      <Footer />
    </QueryClientProvider>
  )
}

export default Layout