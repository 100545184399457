import React, { useContext } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { LayoutContext } from '../../context';
import { getCreationDateFromObjectId } from '../../helpers';
import "../recentBlog/recentBlog.scss";

function RecentBlog({ id = '' }) {
    const { dataList } = useContext(LayoutContext)
    return (
        <div className='container' id={id}>
            <center className='recent-blog-title' data-aos="fade-up">
                <span>Recent Blogs</span>
                <p>Stay informed and inspired with our Recent Blog section. Explore trends, insights, and expert advice in design and construction.</p>
            </center>
            <div className="row recent-blog-card-box-container" data-aos="fade-up" data-aos-delay="200">
                {
                    dataList?.gnanam_engineering_blogs ?
                        dataList?.gnanam_engineering_blogs.data.map(item => (
                            <div className="col-lg-4 col-md-6" key={item._id}>
                                <Link className="card d-block" to={`/blogs/${item.slug}`}>
                                    <div className="card-image">
                                        <LazyLoadImage effect="blur" width={'100%'} src={item.image.url} alt={item.title} />
                                    </div>
                                    <div className="card-body">
                                        <Link>{getCreationDateFromObjectId(item._id)}</Link>
                                        <h3 style={{ marginTop: "25px" }}>{item.title}</h3>
                                        <p className='py-2'>{item.description.substring(0, 140)}...</p>
                                    </div>
                                </Link>
                            </div>
                        ))
                        : null
                }
            </div>
        </div>
    )
}

export default RecentBlog