import { useContext } from "react"
import { ImageSlider } from "../../components"
import { LayoutContext } from "../../context"
import "../clientBrands/clientBrands.scss"

function ClientBrands() {
  const { dataList } = useContext(LayoutContext)
  return (
    <div className='brand-container' data-aos="fade-up" >
      <div className="container">
        <ImageSlider slides={4} sm={3} dots={false} autoplay>
          {
            dataList?.gnanam_engineering_clients && dataList?.gnanam_engineering_clients.data.map((item) => (
              <div key={item._id}><img src={item.image.url} alt="" /></div>
            ))
          }
        </ImageSlider>
      </div>
    </div>
  )
}

export default ClientBrands