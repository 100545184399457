import { useQuery } from '@tanstack/react-query';
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { getServiceCategories } from '../../services';

function CategoryFilter({ handleTabChange, categoryId, loader }) {

    const { isSuccess, data } = useQuery({ queryKey: ['servicesCategories'], queryFn: getServiceCategories })
    console.log(data)
    return (
        <div className="category-tabs" data-aos="fade-up" data-aos-delay="300">
            <button
                className={`category-link ${'all' === categoryId ? 'active' : ''}`}
                onClick={() => handleTabChange('all')}
            >
                {loader === 'all' ? <Spinner animation="border" role="status" size="sm"></Spinner> : 'All'}
            </button>
            {
                isSuccess ?
                    data.data.data.map((category) => (
                        <button
                            key={category._id}
                            className={`category-link ${category._id === categoryId ? 'active' : ''}`}
                            onClick={() => handleTabChange(category._id)}
                            disabled={category === loader}
                        >
                            {loader === category._id ? <Spinner animation="border" role="status" size="sm"></Spinner> : category.name}
                        </button>
                    ))
                    : null
            }
        </div>
    )
}

export default CategoryFilter