import React from 'react'
import "../services/services.scss"

function Services({ id = '' }) {
    return (
        <div className='services container' id={id}>
            <div className="card" data-aos="fade-up" >
                <div className="pt-5 card-body">
                    <img src={require('../../assets/icons/roof.png')} alt="" width={70} />
                    <h4 className="card-title">Metal Roofing</h4>
                    <p className="card-text">Elevate protection and style with Metal Roofing. Durable, contemporary, and built to withstand, our roofing solutions redefine resilience with a touch of modern elegance.</p>
                </div>
            </div>
            <div className="card" data-aos="fade-up" data-aos-delay="200">
                <div className="pt-5 card-body">
                    <img src={require('../../assets/icons/interior-design.png')} alt="" width={70} />
                    <h4 className="card-title">Interior Design</h4>
                    <p className="card-text">Elevate your space with our Interior Design expertise. Unleash creativity, balance aesthetics, and curate a personalized sanctuary for a truly inspiring home.</p>
                </div>
            </div>
            <div className="card" data-aos="fade-up" data-aos-delay="400">
                <div className="pt-5 card-body">
                    <img src={require('../../assets/icons/eco-house.png')} alt="" width={70} />
                    <h4 className="card-title">Green Building</h4>
                    <p className="card-text">Crafting a sustainable tomorrow with Green Building. Our eco-friendly designs prioritize efficiency, well-being, and a harmonious coexistence with the environment.</p>
                </div>
            </div>
            <div className="card" data-aos="fade-up" data-aos-delay="600">
                <div className="pt-5 card-body">
                    <img src={require('../../assets/icons/house.png')} alt="" width={70} />
                    <h4 className="card-title">House Renovation</h4>
                    <p className="card-text">Renew your home with our expert House Renovation services. Transform spaces, blend modern aesthetics, and create a harmonious balance for a redefined living experience.</p>
                </div>
            </div>
        </div>
    )
}

export default Services