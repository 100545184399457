import Aos from 'aos';
import 'aos/dist/aos.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Layout from './Layout';
import './app.scss';
import "./common/common.scss";
import { WorkHistory } from './components';
import BlogView from './pages/BlogView';
import Blogs from './pages/Blogs';
import ServiceView from './pages/ServiceView';
import Home from './pages/home/Home';

function App() {
  Aos.init({
    easing: 'ease',
    duration: 500
  });
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='/blogs' Component={Blogs} />
            <Route path='/blogs/:slug' Component={BlogView} />
            <Route path='/services' Component={WorkHistory} />
            <Route path='/services/:slug/:id' Component={WorkHistory} />
            <Route path='/services/:slug' Component={ServiceView} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App