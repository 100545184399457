import React from 'react'
import { ClientBrands, ClientReviews, Founder, MainBanner, RecentBlog, Services, WorkHistory } from '../../components'
import { HomeApiProvider } from '../../providers/HomeApiProvider'

function Home() {
  return (
    <HomeApiProvider>
      <MainBanner />
      <Founder id='about-us' />
      <Services id='projects' />
      <WorkHistory id='services' />
      <RecentBlog id='blogs' />
      <ClientBrands />
      <ClientReviews />
    </HomeApiProvider>
  )
}

export default Home