import { ContactForm, CtaButton } from '../../components'
import './Footer.scss'
function Footer() {
  return (
    <footer id='contact-us'>
      <div data-aos="fade-up">
        <div className="footer-icon-info-group container" >
          <div className="row m-0" >
            <div className="col-md-4 d-lg-flex align-items-center justify-content-center gap-4 footer-info-icon">
              <img src={require('../../assets/images/location.png')} alt="" />
              <div>
                <div className='fw-bold'><u>Address</u></div>
                <div>1/61-5-A, Ravi Colony, (Ground Floor)</div>
                <div>3rd Street, St. Thomas</div>
                <div>Mount, Chennai-600016.</div>
              </div>
            </div>
            <div className="col-md-4 d-lg-flex align-items-center justify-content-center gap-4 footer-info-icon">
              <img src={require('../../assets/images/working-time.png')} alt="" />
              <div>
                <div className='fw-bold'><u>Working Hours</u></div>
                <div>Monday to Saturday</div>
                {/* <div>09:00-18:30</div> */}
              </div>
            </div>
            <div className="col-md-4 d-lg-flex align-items-center justify-content-center gap-4 footer-info-icon">
              <img src={require('../../assets/images/lot.png')} alt="" />
              <div>
                <div className='fw-bold'><u>Home Sales</u></div>
                <div>No.1, Harish street</div>
                <div>sabarish nagar Bala</div>
                <div>palace, Chennai-600096</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container h-100">
        <div className="row align-items-center gap-4 justify-content-between h-100 footer-wrapper">
          <div className="col-md-7 text-white">
            <div className="col-md-6">
              <h3>Gnanam Engineering</h3>
              <p>Panchatcharam is the Managing Director of Gnanam Engineering and Gnanam Homes and Estate in India Panchatcharam has 20 years of experience, serving in several leadership and customer service roles He was associated with L&T, BGR, Ramky, and ITD, both industrial and residential Property</p>
            </div>
            <div className='footer-link-group'>
              <a href="#" className='footer-link'>HOME</a> |
              <a href='#about-us' className='footer-link'>ABOUT US</a> |
              <a href='#services' className='footer-link'>SERVICES</a> |
              <a href='#projects' className='footer-link'>PROJECTS</a> |
              <a href='#blogs' className='footer-link'>BLOG</a> |
              <a href='#contact-us' className='footer-link'>CONTACT US</a>
            </div>
            <p><b>Support</b>: Consultation [or] enquires. or call <a href="tel:919940256161" className='footer-link fw-bold'>+91 99402 56161</a> , Mail <a href="mailto:contact@gnanameng.com" className='footer-link fw-bold'>contact@gnanameng.com</a></p>
            <p>Copyright @2024 <a href="" className='footer-link'>ejnarstudios</a> | Allrights reserved by gnanameng privacy policy | Terms of use | Legal</p>
          </div>
          <div className="col-md-4">
            <h3 align="right" className='text-white'>Just say Hello</h3>
            <p align="right" className='text-white'>Let us know more about you</p>
            <ContactForm />
          </div>
        </div>
      </div>
      <CtaButton />
    </footer>
  )
}

export default Footer