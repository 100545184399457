import { useContext } from 'react'
import { ImageSlider } from '../../components'
import { LayoutContext } from '../../context'
import "../clientReviews/clientReviews.scss"

function ClientReviews() {
    const { dataList } = useContext(LayoutContext)

    return (
        <section className='mb-100'>
            <div className='container'>
                <center className='recent-blog-title' data-aos="fade-up" data-aos-delay="200">
                    <span>Words from <span className='text-dark'>Our Clients</span></span>
                    <p>Client Chronicles Hear It from Them! Discover the words that resonate from our valued clients. Real stories, real satisfaction – because your success is our success</p>
                </center>
                <ImageSlider xl={2} lg={2} autoplay sliderClassName="client-slider" slides={2}>
                    {
                        dataList?.gnanam_engineering_testimonials && dataList?.gnanam_engineering_testimonials?.data.map(item => (
                            <div key={item._id} className="client-slider-wrapper" data-aos="fade-up" data-aos-delay="200">
                                <div className="client-card">
                                    <div className="card-body">
                                        <p>{item.comment}</p>
                                    </div>
                                    <div className="client-info">
                                        <div className="client-image">
                                            <img src={item.image.url} />
                                        </div>
                                        <div>
                                            <div className="client-name">{item.name}</div>
                                            <div className="client-designation">Reviewer</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </ImageSlider>
            </div>
        </section>
    )
}

export default ClientReviews