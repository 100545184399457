export const getCreationDateFromObjectId = (objectId) => {
    // Convert the hex string of the ObjectId to a number
    const timestamp = parseInt(objectId.substring(0, 8), 16);

    // Create a new Date object using the timestamp (multiply by 1000 as it's in seconds)
    const creationDate = new Date(timestamp * 1000);

    // Define options for formatting the date
    const options = { year: 'numeric', month: 'short', day: 'numeric' };

    // Return the formatted date string
    return creationDate.toLocaleDateString('en-US', options);
};
