import React from 'react'
import { FaHeadphones, FaWhatsapp } from 'react-icons/fa6'

function CtaButton() {
    return (
        <div>
            <a href="https://wa.me/+918270211001?text=Hello!" target='_blank' className='ct-link'> <FaWhatsapp size={20} /></a>
            <a href='tel:919940256161' target='_blank' className='ct-link two'> <FaHeadphones size={20} /></a>
        </div>
    )
}

export default CtaButton