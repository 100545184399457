import axios from 'axios'
import React from 'react'
import { useForm } from "react-hook-form"
import Swal from 'sweetalert2'


function ContactForm() {
    const { register, handleSubmit, reset } = useForm({
        shouldUseNativeValidation: true,
    })
    const onSubmit = async (data) => {
        axios.post('https://ejnarstudios.com/ej-mailer/api/send-mail', data).then(response => {
            const { status, message } = response.data
            const successMessage = `We’ve received your message, Our team contact you shortly`
            Swal.fire({
                title: status ? 'Thank you!' : 'Whoo!',
                text:status ? successMessage : message,
                icon: status ? 'success' : 'warning',
                confirmButtonText: 'Okay',
                confirmButtonColor: 'black'
            })
        }).finally(() => reset())
    }
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input type="text" {...register('name', {
                    required: true
                })} placeholder='Enter your name' className='form-input' required />
                <input type="email" {...register('email', {
                    required: true
                })} placeholder='Enter your email' className='form-input' required />
                 <input type="number" maxLength={12} {...register('mobile', {
                    required: true,
                    valueAsNumber: true,
                })} placeholder='Enter your mobile' className='form-input' required />
                <div className='form-button-wrapper'>
                    <textarea cols="10" className='form-input' {...register('message')} rows="2" placeholder='Message' required></textarea>
                    <button className='form-button'>Submit</button>
                </div>
            </form>
        </div>
    )
}

export default ContactForm