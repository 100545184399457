import { useQuery } from '@tanstack/react-query'
import React from 'react'
import Placeholder from 'react-bootstrap/Placeholder'
import { FaArrowRightLong } from 'react-icons/fa6'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import { getAllBlogs } from '../../services'
import './blogGrid.css'

const BlogGrid = () => {
    const { isSuccess, data, isFetching, isLoading } = useQuery({ queryKey: ['blogs'], queryFn: getAllBlogs })
    if (isFetching && isLoading) return (
        <div className='container py-3'>
            <div className="row">
                <div className="col-lg-8">
                    <Placeholder className="rounded" style={{ minHeight: 450 }} xs={12} size="lg" />
                    <Placeholder className="rounded mt-2" style={{ minHeight: 30 }} xs={12} size="md" />
                    <Placeholder xs={12} size="sm" />
                </div>
                <div className="col-lg-4">
                    <Placeholder className="rounded" style={{ minHeight: 180 }} xs={12} size="lg" />
                    <Placeholder className="rounded mt-2 mb-4" style={{ minHeight: 30 }} xs={12} size="md" />
                    <Placeholder className="rounded" style={{ minHeight: 180 }} xs={12} size="lg" />
                    <Placeholder className="rounded mt-2" style={{ minHeight: 30 }} xs={12} size="md" />
                </div>
            </div>
        </div>
    )
    return (
        <div className='container py-5'>
            <div className="row">
                <div className="col-lg-8">
                    <h3 className='mt-3 section_title fw-bold'><span className='text-primary'>Top</span> Article</h3>
                    {
                        isSuccess ?
                            [data.data.data[0]].map(blog => (
                                <Link to={`/blogs/${blog.slug}`} key={blog._id} className='blog-card'>
                                    <LazyLoadImage effect="blur" width={'100%'} src={blog.image.url} className='blog-card-image' />
                                    <h3 className='section_title mt-3 text-primary'>{blog.title}</h3>
                                    <p className='text-dark blog-description'>{blog.description.substring(0, 450)}...</p>
                                    <Link to={`/blogs/${blog.slug}`} className='float-end'>
                                        Read more
                                        <FaArrowRightLong className='ms-2' />
                                    </Link>
                                </Link>
                            ))
                            : null
                    }
                </div>
                <div className="col-lg-4">
                    <p className='lead mt-3 fw-semibold text-primary'>TOP STORIES</p>
                    {
                        isSuccess ?
                            data.data.data.slice(1, 4).map(blog => (
                                <Link to={`/blogs/${blog.slug}`} key={blog._id} className='blog-card'>
                                    <LazyLoadImage effect="blur" width={'100%'} src={blog.image.url} className='blog-image' />
                                    <p className='section_title text-dark mt-2'>{blog.title}</p>
                                </Link>
                            ))
                            : null
                    }
                </div>
            </div>
            <div className="row mt-lg-5 justify-content-between">
                <div className="col-lg-8">
                    <div className="row">
                        {
                            isSuccess ?
                                data.data.data.slice(4, 8).map(blog => (
                                    <div className="col-lg-6">
                                        <Link to={`/blogs/${blog.slug}`} key={blog._id} className='blog-card'>
                                            <LazyLoadImage effect="blur" width={'100%'} src={blog.image.url} className='blog-image' />
                                            <p className='section_title text-dark mt-2'>{blog.title}</p>
                                        </Link>
                                    </div>
                                ))
                                : null
                        }
                    </div>
                </div>
                <div className="col-lg-3">
                    {
                        isSuccess && data.data.data.slice(8, 20).length !== 0 ?
                            <>
                                <p className='fw-semibold text-primary'>TRENDING IN THE TIMES</p>
                                {data.data.data.slice(8, 20).map((blog, i) => (
                                    <Link to={`/blogs/${blog.slug}`} className='d-flex border-bottom py-3' key={blog._id}>
                                        <div><span className='text-primary me-2 fw-bold'>{i + 1}.</span></div>
                                        <small className='section_title text-dark '>{blog.title}</small>
                                    </Link>
                                ))}
                            </>
                            : null
                    }
                </div>
            </div>
        </div>
    )
}

export default BlogGrid