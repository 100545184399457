import React, { useContext } from 'react'
import { LayoutContext } from '../../context'
import ImageSlider from '../imageSlider'
import "./MainBanner.scss"

function MainBanner() {
    const { dataList } = useContext(LayoutContext)


    if (dataList?.gnanam_engineering_banners) return (
        <div className="banner-section">
            <ImageSlider fixedSlide={1} autoplay>
                {
                    dataList?.gnanam_engineering_banners.data.map(item => (
                        <div className="banner" key={item._id}>
                            <div className="banner-image" data-aos="fade-down" style={{ backgroundImage: `url(${item.image.url})` }}>
                                <div className="container">
                                    <h2 className='banner-sub-heading' data-aos="fade-down"><b>GNANAM</b></h2>
                                    <h1 className='display-1 mb-3 w-md-75' data-aos="fade-up" data-aos-delay="500">{item.title}</h1>
                                    <a href='#contact-us' data-aos="fade-down" data-aos-delay="1000">Contact us</a>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </ImageSlider>
        </div>
    )
    return <div className="bg-success" style={{ minHeight: '65vh' }}></div>
}

export default MainBanner