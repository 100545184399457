import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import Placeholder from 'react-bootstrap/Placeholder';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams } from 'react-router-dom';
import RelatedBlogs from '../components/RelatedBlogs';
import { getBlogBySlug } from '../services';

const BlogView = () => {
    const { slug } = useParams()

    useEffect(() => window.scroll(0, 0), [slug])

    const { isSuccess, data, isFetching } = useQuery({
        queryKey: ['blogView', slug], queryFn: async () => {
            return await getBlogBySlug(slug)
        }
    })
    if (isFetching) return (
        <div className='blog-container py-3'>
            <Placeholder className="rounded" style={{ minHeight: 450 }} xs={12} size="lg" />
            <Placeholder className="rounded mt-2" style={{ minHeight: 30 }} xs={12} size="md" />
            <Placeholder xs={12} size="sm" />
        </div>
    )


    const blog = data?.data?.data[0]

    if (isSuccess) {
        return (
            <div className='blog-container'>
                <LazyLoadImage width={'100%'} effect="blur" src={blog.image.url} className='w-100 my-3 rounded blog-banner-image' />
                <h3 className='section_title text-primary'>{blog.title}</h3>
                <p className='text-secondary text-justify'>{blog.description}</p>
                <hr />
                <RelatedBlogs />
            </div>
        )
    }
}

export default BlogView