import React from 'react'
import BlogGrid from '../components/BlogGrid'

const Blogs = () => {
    return (
        <div className='container'>
            <BlogGrid />
        </div>
    )
}

export default Blogs