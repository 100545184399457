import Slider from 'react-slick';

const ImageSlider = ({ children, sm = 1, md = 2, lg = 3, xl = 4, sliderClassName = '', dots = true, slides = 1, autoplay = false, fixedSlide }) => {
    const responsive = [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: xl,
                slidesToScroll: xl,
                slidesToShow: xl,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: lg,
                slidesToScroll: lg,
                slidesToShow: lg,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: md,
                slidesToScroll: md,
                slidesToShow: md,
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: sm,
                slidesToScroll: sm,
                slidesToShow: sm,

            }
        }
    ]
    const settings = {
        dots: dots,
        infinite: true,
        speed: 500,
        slidesToShow: fixedSlide ? fixedSlide : slides,
        slidesToScroll: 1,
        autoplay: autoplay,
        autoplaySpeed: 2000,
        arrows: false,
        responsive: fixedSlide ? [] : responsive
    };
    return <Slider {...settings} className={sliderClassName}>{children}</Slider>;
};

export default ImageSlider;
