import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import { Link, NavLink } from 'react-router-dom';
import { getServiceCategories } from '../../services';

const ServiceDropDown = () => {
    const { data, isSuccess } = useQuery({
        queryKey: ['servicesCategories'], queryFn: getServiceCategories
    });
    return (
        <Dropdown className='d-inline' as={ButtonGroup}>
            <Nav.Link className='nav-links' as={NavLink} to={'/services'} style={{ marginTop: 10 }}>
                Services
                <Dropdown.Toggle className='btn-unstyle text-dark' split />
            </Nav.Link>


            <Dropdown.Menu>
                {
                    isSuccess ?
                        data.data.data.map((item, i) => (
                            <Dropdown.Item key={i} className='d-block' as={Link} to={`/services/${item.slug}/${item._id}`}>
                                {item.name}
                            </Dropdown.Item>
                        ))
                        : null
                }
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ServiceDropDown;
