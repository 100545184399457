import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { getAllBlogs } from '../../services';
import './RelatedBlogs.css';

const RelatedBlogs = () => {
    const { isSuccess, data } = useQuery({
        queryKey: ['blogsRelated'], queryFn: async () => {
            return await getAllBlogs()
        }
    })
    return (
        <div className="row mt-lg-5 justify-content-between">
            <div className="col-lg-8">
                <div className="row">
                    {
                        isSuccess && data.data.data.slice(4, 8).length !== 0 ?
                            data.data.data.slice(4, 8).map(blog => (
                                <div className="col-lg-6">
                                    <Link to={`/blogs/${blog.slug}`} key={blog._id} className='blog-card'>
                                        <LazyLoadImage effect="blur" width={'100%'} className='blog-image' src={blog.image.url} />
                                        <p className='section_title text-primary mt-2'>{blog.title}</p>
                                    </Link>
                                </div>
                            ))
                            : null
                    }
                </div>
            </div>
            <div className="col-lg-4">
                {
                    isSuccess && data.data.data.slice(8, 20).length !== 0 ?
                        <>
                            <p className='fw-semibold text-danger'>TRENDING IN THE TIMES</p>
                            {
                                data.data.data.slice(8, 20).map((blog, i) => (
                                    <Link to={`/blogs/${blog.slug}`} className='d-flex border-bottom py-3' key={blog._id}>
                                        <div><span className='text-danger me-2 fw-bold'>{i + 1}.</span></div>
                                        <small className='section_title text-primary '>{blog.title}</small>
                                    </Link>
                                ))
                            }
                        </>
                        : null
                }
            </div>
        </div>
    )
}

export default RelatedBlogs