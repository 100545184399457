import { useQuery } from '@tanstack/react-query';
import React from 'react';
import Placeholder from 'react-bootstrap/Placeholder';
import { BsTools } from "react-icons/bs";
import { FaPenRuler } from "react-icons/fa6";
import { Gi3DGlasses } from "react-icons/gi";
import { HiColorSwatch } from "react-icons/hi";
import { IoCallSharp } from "react-icons/io5";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams } from 'react-router-dom';
import OtherService from '../components/OtherServices';
import { getServiceBySlug } from '../services';

const ServiceView = () => {
    const { slug } = useParams()

    const { isSuccess, data, isFetching } = useQuery({
        queryKey: ['ServiceView', slug], queryFn: async () => {
            return await getServiceBySlug(slug)
        }
    })
    const item = data?.data?.data[0]

    return (
        <div>
            {
                isFetching ?
                    <div className='blog-container py-3'>
                        <Placeholder className="rounded" style={{ minHeight: 450 }} xs={12} size="lg" />
                        <Placeholder className="rounded mt-2" style={{ minHeight: 30 }} xs={12} size="md" />
                        <Placeholder xs={12} size="sm" />
                    </div>
                    : isSuccess ? (
                        <>
                            <div className='blog-container'>
                                <LazyLoadImage width={'100%'} effect="blur" src={item.image.url} className='w-100 my-3 rounded box-banner-image' />
                                <h3 className='section_title text-primary mb-2'>{item.title}</h3>
                                {item.slug === 'pure-air-solutions' ? <blockquote className='border-start border-primary ps-2'><i>We are pleased to announce our partnership with Pure Air Solutions, Netherlands.</i></blockquote> : null}
                                <p className='text-dark fw-lighter text-justify' dangerouslySetInnerHTML={{ __html: item.description }}></p>
                            </div>
                            <div className="container">
                                <OtherService slug={slug} />
                            </div>
                        </>
                    ) : null
            }
            <div className="bg-primary">
                <div className="container">
                    <div className='row text-center text-white align-items-center'>
                        <div className="col-md-6 col-lg-3 p-5">
                            <HiColorSwatch size={55} className='mb-3' />
                            <p className="mb-3 section_title lead">Color Palette Creation</p>
                            <p className='fw-light text-gray'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam at ea autem magnam error mollitia?</p>
                        </div>
                        <div className="col-md-6 col-lg-3 p-5">
                            <BsTools size={55} className='mb-3' />
                            <p className="mb-3 section_title lead">Customization Options.</p>
                            <p className='fw-light text-gray'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam at ea autem magnam error mollitia?</p>
                        </div>
                        <div className="col-md-6 col-lg-3 p-5">
                            <Gi3DGlasses size={55} className='mb-3' />
                            <p className="mb-3 section_title lead">3D Visualization</p>
                            <p className='fw-light text-gray'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam at ea autem magnam error mollitia?</p>
                        </div>
                        <div className="col-md-6 col-lg-3 p-5">
                            <FaPenRuler size={55} className='mb-3' />
                            <p className="mb-3 section_title lead">Lighting Design.</p>
                            <p className='fw-light text-gray'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam at ea autem magnam error mollitia?</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center p-5">
                <center className='py-5 my-5'>
                    <h3 className='text-secondary mb-5'>WE PROVIDE FAST AND AFFORDABLE SERVICE FOR YOUR PROJECTS!</h3>
                    <a href="tel:919940256161" className='btn btn-primary rounded-0 btn-lg text-white'><IoCallSharp className='me-1' />Call us now!</a>
                </center>
            </div>
        </div>
    )
}

export default ServiceView