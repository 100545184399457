import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa6';
import { Link, NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import brand_logo from "../../assets/images/Logo.png";
import mail_icon from "../../assets/images/mail_icon.png";
import note_icon from "../../assets/images/note_icon.png";
import phone_icon from "../../assets/images/phone_icon.png";
import "../header/header.scss";
import ServiceDropDown from '../ServiceDropDown';

function Header() {
  return (
    <>
      <div className="nav-contact">
        <div className="nav-contact container">
          <a className="contact" href='tel:919940256161' target="_blank">
            <img src={phone_icon} alt="phone_icon" />
            <span>+91 99402 56161</span>
          </a>
          <a className="contact" href='mailto:contact@gnanameng.com' target="_blank">
            <img src={mail_icon} alt="phone_icon" />
            <span>contact@gnanameng.com</span>
          </a>
          <span className="nav-contact-icons">
            <a href="https://www.instagram.com/gnanam_engineering?igsh=MTM2cHZhNWhwa3J3Yg" target="_blank" className='social-link'> <FaInstagram size={20} /></a>
            <a href="https://www.facebook.com/profile.php?id=61552087862359" target="_blank" className='social-link'> <FaFacebook size={20} /></a>
            <a href="https://youtube.com/@GnanamEngineering?si=go98YNtWRK6cLMJl" target="_blank" className='social-link'> <FaYoutube size={20} /></a>
            {/* <a href="https://wa.me/+918270211001?text=Hello!" target='_blank' className='social-link'> <FaWhatsapp size={20} /></a> */}
            {/* <a href="" className='social-link'> <FaLinkedin size={20} /></a> */}
          </span>
        </div>
      </div>

      <Navbar expand="xl" className='navbar shadow'>
        <div className="container">
          <Navbar.Brand as={Link} to='/' className='fw-bold fs-3 text-light' >
            <img src={brand_logo} alt="brand_logo" className='brand-logo' />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ms-auto my-2 my-lg-0"
              navbarScroll
            >
              <Nav.Link className='nav-links' as={NavLink} to='/'>Home</Nav.Link>
              <Nav.Link className='nav-links' as={HashLink} to='/#about-us'>About Us</Nav.Link>
              <ServiceDropDown />
              <Nav.Link className='nav-links' as={HashLink} to='/#projects'>Projects</Nav.Link>
              <Nav.Link className='nav-links' as={NavLink} to='/blogs'>Blogs</Nav.Link>
              <Nav.Link className='nav-links' as={HashLink} to='#contact-us'>Contact Us</Nav.Link>
              <HashLink to={'#contact-us'} className='navbar-button m-3'>
                <img src={note_icon} alt="note_icon" />
                <span className='m-0 px-2'>GET A QUOTE</span>
              </HashLink>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  )
}

export default Header