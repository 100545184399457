import React from 'react'
import founder from "../../assets/images/founder_image.png"
import "../founder/founder.scss"
import tick from "../../assets/images/tick_icon.png"
import { Link } from 'react-router-dom'

function Founder({id=''}) {
  return (
    <div className='founder-box-container container' id={id}>
      <div className="position-relative w-100">
        <div className="image-box-container">
          <div data-aos="fade-up" data-aos-delay="500"></div>
          <img src={founder} alt="founder_image" data-aos="fade-up" />
        </div>

        <div className='founder-details' >
          <h6>Panchatcharam. R</h6>
          <p className='m-0'>Managing Director, GE India</p>
        </div>

      </div>
      <div className="px-lg-4 founder-content" data-aos="fade-up" data-aos-delay="500">
        <div className="founder-title">
          <h1>ABOUT</h1>
          <h1>GNANAM</h1>
        </div>

        <div className='founder-content-inner' data-aos="fade-up" data-aos-delay="700">
          <p>
            <strong>Panchatcharam is the Managing Director of Gnanam Engineering and Gnanam Homes and Estate in India.</strong>
            Panchatcharam has <strong className='px-1'>20 years of experience,</strong> serving in several
            leadership and customer service roles. He was associated with
            <strong className='px-1'>leadership and customer service roles. He was associated with Property</strong>
          </p>
          <p>
            Panchatcharam works on high-rise infrastructure projects, taking on end-to-end Responsibilities such as sales, business development, tendering, planning, and costing execution, He is a
            <strong className='px-1'>member of BNI</strong>
          </p>

          <div className='founder-bio'>
            <img src={tick} alt="tick_icon" />
            <Link>Vision & Mission</Link>
          </div>

          <div className='founder-bio'>
            <img src={tick} alt="tick_icon" />
            <Link>Biography of Business</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Founder