import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { SiBuildkite } from "react-icons/si";
import { Link, useParams } from 'react-router-dom';
import "../workHistory/workHistory.scss";
import CategoryFilter from './CategoryFilter';

function WorkHistory() {
    const { id } = useParams()
    const [activeCategory, setActiveCategory] = useState(id || 'all');
    const [filteredData, setFilteredData] = useState([]);
    const [loader, setLoader] = useState("");

    const handleTabChange = (categoryId) => {
        setLoader(categoryId)
        setActiveCategory(categoryId)
        const filters = {}
        if (categoryId !== 'all') {
            filters['id'] = 'service_id';
            filters['value'] = categoryId;
        }
        axios.post(`https://ej-projects.vercel.app/api/web`, {
            moduleName: "gnanam_engineering_services",
            limit: 50,
            filters: Object.keys(filters).length ? [filters] : []
        }).then(res => {
            setFilteredData(res.data.data)
        }).finally(() => {
            setLoader("")
        })
    };
    useEffect(() => handleTabChange(id || 'all'), [id])
    return (
        <div className='container' id={'service'}>
            <center className='work-history-title'>
                <span data-aos="fade-up">Our Service</span>
                <p data-aos="fade-up" data-aos-delay="200">Building Tomorrow, Today! Our construction services boast a rich history of crafting spaces that stand the test of time From groundbreaking designs to meticulous execution, we've been shaping a legacy of excellence in construction. Discover the journey with us as we turn visions into structures</p>
            </center>
            <div className='tab-pane'>
                <CategoryFilter handleTabChange={handleTabChange} categoryId={activeCategory} loader={loader} />
                <div className="row justify-content-center">
                    {filteredData.length ? filteredData.map((project, i) => (
                        <div key={project.id} data-aos="fade-up" data-aos-delay={`${i + 4}00`} className="col-lg-4 col-md-6 col-sm-12">
                            <Link to={`/services/${project.slug}`} className="card d-block">
                                <div className="card-image">
                                    <img src={project.image.url} alt={project.title} />
                                </div>
                                <center className="card-body">
                                    <SiBuildkite className='history_icon' />
                                    <h3 style={{ marginTop: '25px' }}>{project.title}</h3>
                                    <p className="py-2" dangerouslySetInnerHTML={{ __html: project.description.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 200) + "..." }}></p>
                                </center>
                            </Link>
                        </div>
                    )) : null}
                </div>
            </div>
        </div>
    )
}

export default WorkHistory